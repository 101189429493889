// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
} from 'react-icons/fa';
// import images
import AboutImg from '../src/assets/img/about/plate.png';
import ModelWhiteImg from '../src/assets/img/model-white.png';
import ModelBlackImg from '../src/assets/img/model-black.png';
import MenuImg1 from '../src/assets/img/menu/1.png';
import MenuImg2 from '../src/assets/img/menu/2.png';
import MenuImg3 from '../src/assets/img/menu/3.png';
import MenuImg4 from '../src/assets/img/menu/4.png';
import SignatureImg from '../src/assets/img/team/signature.png';
import ChefImg from '../src/assets/img/team/chef.png';
import Avatar1 from '../src/assets/img/testimonial/avatar1.png';
import Avatar2 from '../src/assets/img/testimonial/avatar2.png';
import Avatar3 from '../src/assets/img/testimonial/avatar3.png';

export const navData = [
  { href: 'https://order.dre.ma', name: 'Passer La Commande' },
  // { href: '/', name: 'home' },
  // { href: '/', name: 'about' },
  // { href: '/', name: 'menu' },
  // { href: '/', name: 'team' },
  // { href: '/', name: 'testimonials' },
  // { href: '/', name: 'book a table' },
  // { href: '/', name: 'contact' },
];

const handelClick = () => window.open('https://order.dre.ma', '_blank')

export const heroData = {
  pretitle: 'BIENVENUE',
  title: 'Nori Restaurant',
  subtitle: 'Nori vous offre un cadre convivial et chaleureux, un service rapide et efficace, une carte extrêmement riche et remplie de produits de premier choix. Nos créations sont réalisées à partir d\'ingrédients frais et de qualité, de façon artisanale par nos chefs sushimen.',
  btnText: 'Passer La Commande',
  btnTextOnClick: handelClick,
};

export const socialData = [
  { href: '#', icon: '' },
  { href: '#', icon: '' },
  { href: '#', icon: '' },
  { href: '#', icon: '' },
  { href: 'https://www.instagram.com/norirestaurant', icon: <FaInstagram size={40} />, target: '_blank' },
];

export const aboutData = {
  pretitle: 'our story',
  title: 'who we are',
  subtitle:
    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet consequatur, quasi minima molestiae ex exercitationem culpa quisquam architecto quaerat, iusto dolores labore, sapiente magni rem commodi aperiam ad dolorem neque ducimus. Placeat vel non quod quis pariatur a aperiam, aliquam adipisci voluptatum voluptatem sit cupiditate dolore natus beatae earum omnis.',
  btnText: 'find out more',
  btnTextOnClick: handelClick,
  image: AboutImg,
};

export const menuData = {
  title: 'delicious flavour of autumn',
  subtitle: 'view all menu for tasty meal today',
  modelImg: ModelWhiteImg,
  btnText: 'view complete menu',
  btnTextOnClick: handelClick,
  menuItems: [
    {
      image: MenuImg1,
      name: 'Chocolate Cake',
      price: '17,99 DH',
      description: 'Class aptent taciti ciosqu litora torquent per',
    },
    {
      image: MenuImg2,
      name: 'Veggie Burger',
      price: '29,49 DH',
      description: 'Class aptent taciti ciosqu litora torquent per',
    },
    {
      image: MenuImg3,
      name: 'King Burger',
      price: '38,50 DH',
      description: 'Class aptent taciti ciosqu litora torquent per',
    },
    {
      image: MenuImg4,
      name: 'Mexican Burger',
      price: '26,99 DH',
      description: 'Class aptent taciti ciosqu litora torquent per',
    },
  ],
};

export const teamData = {
  pretitle: 'our team',
  title: 'meet our chef',
  sub1: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis adipisci repudiandae enim ratione corrupti voluptatum suscipit distinctio dolor.',
  sub2: 'Sequi exercitationem quae deserunt reiciendis nesciunt perferendis atque quisquam, odit facere! Minima esse reiciendis, magnam fugiat totam maxime consequatur perspiciatis voluptas consequuntur.',
  name: 'sara peter',
  occupation: 'executive chef',
  signatureImg: SignatureImg,
  chefImg: ChefImg,
};

export const testimonialData = {
  title: "what client's say ",
  subtitle: '1500+ statisfied clients',
  modelImg: ModelWhiteImg,
  slider: [
    {
      message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
      image: Avatar2,
      name: 'Nom Prénom',
      occupation: 'CEO of Delightful',
    },
    // {
    //   message:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
    //   image: Avatar2,
    //   name: 'John Doe',
    //   occupation: 'CEO of Delightful',
    // },
    // {
    //   message:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
    //   image: Avatar3,
    //   name: 'Henry A.',
    //   occupation: 'CEO of Delightful',
    // },
  ],
};

export const reservationData = {
  title: 'booking form',
  // subtitle: 'Call (800) 123-4567 from 5AM - 11PM daily, or book online with OpenTable.Reservations required for parties of 6 or more.',
  modelImg: ModelBlackImg,
  btnText: 'make a reservation',
};

export const newsletterData = {
  title: 'Location',
  subtitle: '8, Rue D\'ifrane, CIL - Casablanca, Maroc',
  placeholder: 'Subscribe our delicious dishes',
  btnText: <a href='https://goo.gl/maps/tvhwDBsaotyYLMeV6'>Maps</a>,
};

export const footerData = {
  contact: {
    title: 'contact location',
    address: 'Adresse : 8, Rue d\'ifrane, CIL - Casablanca, Maroc',
    phone: <div>
      <div><a href='tel:0522395555'>05-22-39-55-55</a></div>
      <div><a href='tel:0522393222'>05-22-39-32-22</a></div>
    </div>
  },
  hours: {
    title: 'working hours',
    program: [
      {
        days: 'DU LUNDI AU SAMEDI',
        hours: 'DE 12H00 À 15H00 ET DE 18H30 À 23H00',
      },
      {
        days: 'LE DIMANCHE',
        hours: 'DE 12H00 À 23H00 NON STOP',
      },
    ],
  },
  social: {
    title: 'social network',
    icons: [
      { href: '/', icon: <FaYoutube /> },
      { href: '/', icon: <FaFacebookF /> },
      { href: '/', icon: <FaInstagram /> },
      { href: '/', icon: <FaPinterestP /> },
      { href: '/', icon: <FaDiscord /> },
    ],
  },
};
